import {
  createRouter,
  createWebHistory
} from "vue-router";
import {
  checkLogin
} from "../api/user"
import store from "@/store/index";
import {
  SET_ALL_DATA
} from "@/store/modules/app/type";
import {
  setCookieKey
} from "@/utils/cookie";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({
    y: 0
  }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: [{
      path: "/",
      redirect: "/index",
      meta: {
        title: "一网通服务延伸首页",
        needLogin: false,
        keepAlive: true
      }
    },
    {
      path: "/index",
      component: () => import("../views/Index"),
      name: "index",
      meta: {
        title: "一网通服务延伸首页",
        needLogin: false,
        keepAlive: true
      }
    },
    {
      path: "/serviceItem/",
      redirect: "/serviceItem/index",
      component: () => import("../views/layout/Layout"),
      name: "serviceItemMain",
      meta: {
        title: "服务列表",
        needLogin: false,
        keepAlive: true
      },
      children: [{
          path: "index",
          component: () => import("../views/serviceItem/index"),
          name: "serviceItemIndex",
          meta: {
            title: "服务列表",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "satisfied",
          component: () => import("../views/satisfied/index"),
          name: "satisfied",
          meta: {
            title: "二次供水及直饮水设施普查",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "UserSatisfied",
          component: () => import("../views/satisfied/UserSatisfied"),
          name: "UserSatisfied",
          meta: {
            title: "二次供水及直饮水设施普查",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "satisfiedlist",
          component: () => import("../views/satisfied/satisfiedlist.vue"),
          name: "satisfiedlist",
          meta: {
            title: "我的普查表",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "satisfiedTempList",
          component: () => import("../views/satisfied/satisfiedTempList.vue"),
          name: "satisfiedTempList",
          meta: {
            title: "暂存本地的设施普查",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "genWaterSurvey",
          component: () => import("../views/satisfied/GenWaterSurvey.vue"),
          name: "GenWaterSurvey",
          meta: {
            title: "二次供水及直饮水设施定位",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "waterSurveyTrouble",
          component: () => import("../views/satisfied/WaterSurveyTrouble.vue"),
          name: "WaterSurveyTrouble",
          meta: {
            title: "发现问题",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "satisfiedReviewList",
          component: () => import("../views/satisfied/SatisfiedReviewList.vue"),
          name: "satisfiedReviewList",
          meta: {
            title: "二次供水及直饮水设施复查",
            needLogin: false,
            keepAlive: true
          }
        },


        {
          path: "waterSurveyMain",
          component: () => import("../views/satisfied/WaterSurveyMain.vue"),
          name: "WaterSurveyMain",
          meta: {
            title: "二次供水及直饮水设施普查信息",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "waterSurveyOrgManage",
          component: () => import("../views/satisfied/WaterSurveyOrgManage.vue"),
          name: "WaterSurveyOrgManage",
          meta: {
            title: "组织与管理",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "waterSurveycleanSterilize",
          component: () => import("../views/satisfied/WaterSurveycleanSterilize.vue"),
          name: "WaterSurveycleanSterilize",
          meta: {
            title: "清洗消毒",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "waterSurveyWaterTest",
          component: () => import("../views/satisfied/WaterSurveyWaterTest.vue"),
          name: "WaterSurveyWaterTest",
          meta: {
            title: "水质检测",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "waterSurveyOther",
          component: () => import("../views/satisfied/WaterSurveyOther.vue"),
          name: "WaterSurveyOther",
          meta: {
            title: "安全评估、其他",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "waterSurveyDevice",
          component: () => import("../views/satisfied/WaterSurveyDevice.vue"),
          name: "WaterSurveyDevice",
          meta: {
            title: "设施-生活用水系统",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "waterSurveyFire",
          component: () => import("../views/satisfied/WaterSurveyFire.vue"),
          name: "WaterSurveyFire",
          meta: {
            title: "设施-消防系统",
            needLogin: false,
            keepAlive: true
          }
        },{
          path: "markerMap",
          component: () => import("../views/satisfied/markerMap.vue"),
          name: "markerMap",
          meta: {
            title: "普查设施位置定位",
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "detailed",
          component: () => import("../views/serviceItem/detailed"),
          name: "serviceItemDetailed",
          meta: {
            title: "服务详情",
            needLogin: false,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/orders/",
      redirect: "/orders/index",
      component: () => import("../views/layout/Layout"),
      name: "ordersMain",
      meta: {
        title: "订单",
        needLogin: true,
        keepAlive: true
      },
      children: [{
          path: "index",
          component: () => import("../views/orders/Index"),
          name: "ordersIndex",
          meta: {
            title: "订单",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "appointmentAdd",
          component: () => import("../views/orders/AppointmentAdd"),
          name: "AppointmentAdd",
          meta: {
            title: "服务预约",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "detailed",
          component: () => import("../views/orders/Detailed"),
          name: "DetailedInLogin",
          meta: {
            title: "订单详情",
            desc: '查看订单详细必须登录状态',
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "cancelAppointment",
          component: () => import("../views/orders/loop/CancelAppointment"),
          name: "CancelAppointment",
          meta: {
            title: "取消预约单",
            desc: '取消预约单',
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "verifyAppointment",
          component: () => import("../views/orders/loop/VerifyAppointment"),
          name: "verifyAppointment",
          meta: {
            title: "确认取消预约单",
            desc: '确认取消预约单',
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "dispatchAppointment",
          component: () => import("../views/orders/loop/dispatchAppointment"),
          name: "dispatchAppointment",
          meta: {
            title: "预约派单",
            desc: '预约派单',
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "contactClients",
          component: () => import("../views/orders/loop/contactClients"),
          name: "contactClients",
          meta: {
            title: "联系客户",
            desc: '联系客户',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "blackAppointment",
          component: () => import("../views/orders/loop/blackAppointment"),
          name: "blackAppointment",
          meta: {
            title: "退单",
            desc: '退单',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "genOrder",
          component: () => import("../views/orders/loop/GenOrder"),
          name: "genOrder",
          meta: {
            keepAlive: false,
            title: "生成订单",
            desc: '生成订单',
            needLogin: true,
          }
        }, {
          path: "modifyOrder",
          component: () => import("../views/orders/loop/ModifyOrder"),
          name: "modifyOrder",
          meta: {
            keepAlive: false,
            title: "修改订单",
            desc: '修改订单',
            needLogin: true,
          }
        }, {
          path: "startWork",
          component: () => import("../views/orders/loop/StartWork"),
          name: "startWork",
          meta: {
            title: "项目开工",
            desc: '项目开工',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "pauseWork",
          component: () => import("../views/orders/loop/PauseWork"),
          name: "pauseWork",
          meta: {
            title: "项目暂停",
            desc: '项目暂停',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "completedWork",
          component: () => import("../views/orders/loop/CompletedWork"),
          name: "completedWork",
          meta: {
            title: "项目完工",
            desc: '项目完工',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "confirmFee",
          component: () => import("../views/orders/loop/ConfirmFee"),
          name: "confirmFee",
          meta: {
            title: "确认费用",
            desc: '确认费用',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "genPact",
          component: () => import("../views/orders/loop/GenPact"),
          name: "genPact",
          meta: {
            title: "生成服务协议",
            desc: '生成服务协议',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "signPact",
          component: () => import("../views/orders/loop/SignPact"),
          name: "signPact",
          meta: {
            title: "签署服务协议",
            desc: '签署服务协议',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "signSuccess",
          component: () => import("../views/orders/loop/SignSuccess"),
          name: "signSuccess",
          meta: {
            title: "签署服务协议成功",
            desc: '签署服务协议成功',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "cancelOrder",
          component: () => import("../views/orders/loop/CancelOrder"),
          name: "cancelOrder",
          meta: {
            title: "取消订单",
            desc: '取消订单',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "verifyPayCancelOrder",
          component: () => import("../views/orders/loop/VerifyPayCancelOrder"),
          name: "verifyPayCancelOrder",
          meta: {
            title: "订单确认费用",
            desc: '订单确认费用',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "verifyCancelOrder",
          component: () => import("../views/orders/loop/VerifyCancelOrder"),
          name: "verifyCancelOrder",
          meta: {
            title: "确认取消订单",
            desc: '确认取消订单',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "detectSampling",
          component: () => import("../views/orders/loop/DetectSampling"),
          name: "detectSampling",
          meta: {
            title: "检测取样",
            desc: '检测取样',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "pushReport",
          component: () => import("../views/orders/loop/PushReport"),
          name: "pushReport",
          meta: {
            title: "发布检测报告",
            desc: '发布检测报告',
            needLogin: true,
            keepAlive: true
          }
        }, {
          path: "detectContactClients",
          component: () => import("../views/orders/loop/DetectContactClients"),
          name: "detectContactClients",
          meta: {
            title: "联系客户",
            desc: '联系客户',
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "genWaterDetectPact",
          component: () => import("../views/orders/loop/GenWaterDetectPact"),
          name: "genWaterDetectPact",
          meta: {
            title: "生成协议",
            desc: '生成协议',
            needLogin: true,
            keepAlive: true
          }
        },
      ]
    },
    {
      path: "/payment/",
      redirect: "/payment/index",
      component: () => import("../views/layout/Layout"),
      name: "paymentMain",
      meta: {
        title: "费用支付",
        needLogin: false,
        keepAlive: true
      },
      children: [{
          path: "index",
          component: () => import("../views/payment/Index"),
          name: "paymentIndex",
          meta: {
            title: "支付",
            desc: '支付',
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "wxPayNotify",
          component: () => import("../views/payment/WxPayNotify"),
          name: "WxPayNotify",
          meta: {
            title: "微信支付结果",
            desc: '微信支付结果',
            needLogin: false,
            keepAlive: true
          }
        },
        {
          path: "otherPay",
          component: () => import("../views/payment/OtherPay"),
          name: "otherPay",
          meta: {
            title: "其他付款方式",
            desc: '其他付款方式',
            needLogin: false,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/users/",
      redirect: "/users/index",
      component: () => import("../views/layout/Layout"),
      name: "usersMain",
      meta: {
        title: "我的",
        needLogin: true,
        keepAlive: true
      },
      children: [{
          path: "index",
          component: () => import("../views/users/Index"),
          name: "usersIndex",
          meta: {
            title: "我的",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "address",
          component: () => import("../views/users/address"),
          name: "address",
          meta: {
            title: "地址信息",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "addressEdit",
          component: () => import("../views/users/addressEdit"),
          name: "addressEdit",
          meta: {
            title: "编辑地址信息",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "addInvoice",
          component: () => import("../views/users/addInvoice"),
          name: "addInvoice",
          meta: {
            title: "保存开票信息",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "invoiceApply",
          component: () => import("../views/users/invoiceApply"),
          name: "invoiceApply",
          meta: {
            title: "申请发票",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "invoiceList",
          component: () => import("../views/users/invoiceList"),
          name: "invoiceList",
          meta: {
            title: "我的发票",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "invoiceRecord",
          component: () => import("../views/users/invoiceRecord"),
          name: "invoiceRecord",
          meta: {
            title: "开票详细信息",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "invoiceHandle",
          component: () => import("../views/users/invoiceHandle"),
          name: "invoiceHandle",
          meta: {
            title: "开票确认",
            needLogin: true,
            keepAlive: true
          },
        }, {
          path: "invoiceModify",
          component: () => import("../views/users/invoiceModify"),
          name: "invoiceModify",
          meta: {
            title: "编辑开票信息",
            needLogin: true,
            keepAlive: true
          },
        },
        {
          path: "userInfo",
          component: () => import("../views/users/userInfo"),
          name: "usersInfo",
          meta: {
            title: "个人信息",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "customer",
          component: () => import("../views/users/customer"),
          name: "customer",
          meta: {
            title: "客服",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "amap",
          component: () => import("../views/users/amap"),
          name: "Amap",
          meta: {
            title: "选择地址",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "userMessage",
          component: () => import("../views/users/UserMessage"),
          name: "userMessage",
          meta: {
            title: "我的消息",
            needLogin: true,
            keepAlive: true
          }
        },

        {
          path: "orderTransfer",
          component: () => import("../views/users/orderTransfer"),
          name: "orderTransfer",
          meta: {
            title: "订单转派",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "oderTransferUser",
          component: () => import("../views/users/oderTransferUser"),
          name: "oderTransferUser",
          meta: {
            title: "订单指派人员",
            needLogin: true,
            keepAlive: true
          }
        },


      ]
    },
    {
      path: "/serviceAgreement/",
      redirect: "/serviceAgreement/index",
      component: () => import("../views/layout/Layout"),
      name: "serviceAgreementMain",
      meta: {
        title: "服务协议",
        needLogin: true,
        keepAlive: true
      },
      children: [{
          path: "index",
          component: () => import("../views/serviceAgreement/index"),
          name: "serviceAgreementIndex",
          meta: {
            title: "服务协议",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "sign",
          component: () => import("../views/serviceAgreement/sign"),
          name: "serviceAgreementSign",
          meta: {
            title: "协议签署",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "mergeSign",
          component: () => import("../views/serviceAgreement/mergeSign"),
          name: "serviceAgreementMergeSign",
          meta: {
            title: "协议合并签署",
            needLogin: true,
            keepAlive: true
          }
        },
        {
          path: "appoint",
          component: () => import("../views/serviceAgreement/appointAgreement"),
          name: "appointAgreement",
          meta: {
            title: "服务协议",
            needLogin: true,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/evaluation/",
      redirect: "/evaluation/index",
      component: () => import("../views/layout/Layout"),
      name: "evaluationMain",
      meta: {
        title: "服务评价",
        needLogin: true,
        keepAlive: true
      },
      children: [{
        path: "index",
        component: () => import("../views/evaluation/index"),
        name: "evaluationIndex",
        meta: {
          title: "服务评价",
          needLogin: true,
          keepAlive: true
        }
      }, {
        path: "detailed",
        component: () => import("../views/evaluation/detailed"),
        name: "evaluationDetailed",
        meta: {
          title: "评价详情",
          needLogin: true,
          keepAlive: true
        }
      }]
    },
    {
      path: "/demo/",
      redirect: "/demo/index",
      component: () => import("../views/layout/Layout"),
      name: "demoMain",
      meta: {
        title: "测试",
        needLogin: false,
      },
      children: [{
        path: "page",
        component: () => import("../views/demo/page"),
        name: "page",
        meta: {
          title: "测试",
          needLogin: false,
        }
      }]
    }
  ],
});
router.beforeEach((to, from, next) => {
  let {
    title,
    needLogin
  } = to.meta;
  if (needLogin) {
    checkLogin().then(result => {
      if (result.code == process.env.VUE_APP_SUCCESS_CODE) {
        //异步存储状态
        if (result.data) {
          store.dispatch(`app/${SET_ALL_DATA}`, result.data)
          setCookieKey('isLogin', true)
          setCookieKey('name', result.data.account.name)
          setCookieKey('userPhone', result.data.account.phone)
          setCookieKey('isManage', result.data.isManage)
          if (result.data && result.data.account && result.data.account.cardNumber) {
            setCookieKey("idNumber", result.data.account.cardNumber)
          } else {
            setCookieKey("idNumber", '')
          }
        }
      }
    });
  }
  document.title = title;
  next();
});
router.afterEach((to, from) => {
  if (!window.sessionStorage.firstUrl) {
    window.sessionStorage.firstUrl = window.location.href
  }
});

export default router;